@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Plus Jakarta Sans", sans-serif;
  }


  a {
    @apply text-blue-600 underline;
  }

  select {
    @apply border border-gray-300 rounded py-2 px-4;
  }
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.pulsating-circle {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Waveform Animation */
@keyframes waveform {

  0%,
  100% {
    height: 20%;
  }

  50% {
    height: 100%;
  }
}

.waveform-bar {
  animation: waveform 1s ease-in-out infinite;
}

/* Gradient Orb */
@keyframes ping {

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.gradient-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.gradient-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

[data-aos="zoom-y-out"] {
  opacity: 0;
  transform: scale(0.9);
  /* Start slightly smaller */
  transition-property: opacity, transform;
}

[data-aos="zoom-y-out"].aos-animate {
  opacity: 1;
  transform: scale(1);
  /* Scale up to normal size */
}